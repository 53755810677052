html, #app {
  background: var(--ds-colour-surfaceSecondary);
  scroll-behavior: smooth;
}

@media screen and (max-width: 768px) {
  #app {
    background: var(--ds-colour-surfaceBackground);
  }
}

/*# sourceMappingURL=base.8c69fb62.css.map */
